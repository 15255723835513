const images = [...document.querySelectorAll('.imageSlider img.header__slide')];
const imageCount = images.length;

if (images.length >= 1) {
  images[0].classList.toggle('isActiveSlide');
}

let index = 0;

function initSlider() {
  if (index == imageCount - 1) {
    images[imageCount - 1].classList.toggle('isActiveSlide');
    images[0].classList.toggle('isActiveSlide');
    index = 0;
  } else {
    images[index].classList.toggle('isActiveSlide');
    images[index+1].classList.toggle('isActiveSlide');
    index ++;
  }
  setTimeout(() => {
    initSlider();
  }, 5000)
}

export default initSlider;
export {
  images,
  imageCount
};
