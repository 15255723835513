/**
 * @package {PACKAGE NAME}
 * 
 * Entry point for Javascript package
 */

import "core-js/stable";
import fitText from './lib/fitText';
import initSlider, { images } from './components/imageSlider';

if (images.length >= 1) {
  initSlider();
}


// Make header text fit container
const fitTarget = document.querySelector('.heroSection.home .heroSection__title');
if (fitTarget)
    window.fitText(fitTarget);


const headerChager = document.getElementById('openNavigation');
const headerLogo = document.querySelector('.header__logo');
const primaryMenu = document.querySelector('.primary_menu');

headerChager.addEventListener('click', () => {
  headerLogo.classList.toggle('isOpenHeaderLogo');
  primaryMenu.classList.toggle('isOpenHeaderMenu');
})